import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../../Components/Layout/Layout'
import bgimage from '../../Images/Home/transfer_bg.jpg'
import loaderimg from '../../Images/Loading/Loader.gif'
import Select from 'react-select'
import Axios from 'axios'
import { Stripe } from 'stripe'
import { Modal,ModalBody,ModalHeader } from 'reactstrap'
import Loading from '../../Components/Loading/Loader'
import {
  ApiEndPoint,
  TransferCheckoutToken,
  CustomerDomainName,
  CurrencyConverter
} from '../../Components/GlobalData/GlobalData'
import { ToastContainer, toast } from 'react-toastify'
import payment from '../../Images/Logo/payment-img.png'
import { useNavigate } from 'react-router-dom'
import TransferNew from '../../Components/SearchBar/TransferNew'

var StripePayment = 0
var StripeCurrency = ''
var exchangeRate = 0
function TransferCheckout () {
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const Picurl = CustomerDomainName()
  const navigate = useNavigate()
  const endpoint = ApiEndPoint()
  const token = TransferCheckoutToken()
  const Transferid = sessionStorage.getItem('TransferID')
  const TransferDetail = useSelector(state => state.hotels.TrSeResponse)
  const NewSelectedTransfer = TransferDetail?.transfers_list.filter(
    item => Number(item.destination_id) === Number(Transferid)
  )
  const [checkoutId, setCheckoutId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentStatus1, setPaymentStatus1] = useState(false);
  const [paymentStatusResponse, setPaymentStatusResponse] = useState([]);
  const [paymentMessage, setPaymentMessage] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isBooking, setIsBooking] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(true);
  const [buttonText, setButtonText] = useState(true);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState(
    NewSelectedTransfer[0]
  )
  var moreDestin = []
  if (NewSelectedTransfer[0].more_destination_details !== '') {
    moreDestin = JSON.parse(NewSelectedTransfer[0].more_destination_details)
  }
  const [moreDestination, setMoreDestination] = useState(moreDestin)
  const [selectedNationality, setSelectedNationality] = useState('')
  const [gestinfo, setGestinfo] = useState({
    title: '',
    firstname: '',
    lastname: '',
    dateofbirth: '',
    numbercode: '',
    email: '',
    phonenumber: '',
    pno: '',
    country: '',
    nationality: ''
  })
  useEffect(() => {
    checkPaymentStausFirst();
    loadFormData();
    fetchData();
  }, []);

  const checkPaymentStausFirst=async()=>{
    const currentURL = window.location.href
    const parsedUrl = new URL(currentURL);
    const queryParams = parsedUrl.searchParams;
    const id = queryParams.get('id');
    if(id===null){
      return;
    }
    let statusNew;
    try {
      statusNew = await getPaymentStatus(checkoutId);
    } catch (error) {
     
      console.error("Error getting payment status:", error);
      return  "An error occurred while getting the payment status";
    };
    if(statusNew.result.code==='000.200.000'){
      toast.error(statusNew.result.description , {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    };
    if(statusNew.result.code==='600.200.500'){
      toast.error(statusNew.result.description , {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    };
    if(statusNew.result.code==='100.100.400'){
      toast.error('Please Enter Card Holder Name' , {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    };
    if(statusNew.result.code==='700.500.003'){
      toast.error(statusNew.result.description , {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    };
    if(statusNew.result.code==='000.100.110'){
      setButtonText(false);
      setPaymentStatus1(true);
      setPaymentStatusResponse(statusNew);
      setPaymentMessage(statusNew.result.description);
    };
  };
  async function fetchData () {
    Axios.get(
      'https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd'
    )
      .then(response => {
        const countryOptions = response?.data.map(country => ({
          value: country.name.common,
          label: country.name.common,
          flag: country.flags.png,
          phoneCode: country.idd.root + country.idd.suffixes[0]
        }))
        setCountryList(countryOptions)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
  const handlegestchange = e => {
    const { value, name } = e.target;
    setGestinfo(prevgestinfo => ({ ...prevgestinfo, [name]: value }))
  }
  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption)
    setSelectedNationality(selectedOption.value)
    setGestinfo(prevdata => ({
      ...prevdata,
      phonenumber: selectedOption.phoneCode,
      numbercode: selectedOption.phoneCode,
      nationality: selectedOption.value
    }))
  }

  const BookTransfer = async newdata => {
    const LeadPassengerData = {
      lead_passenger_details: {
        lead_title: gestinfo.title,
        lead_first_name: gestinfo.firstname,
        lead_last_name: gestinfo.lastname,
        lead_email: gestinfo.email,
        lead_date_of_birth: gestinfo.dateofbirth,
        lead_country: gestinfo.numbercode,
        lead_phone: gestinfo.phonenumber,
        passport_img: ''
      },
      other_passenger_details: [],
      transfer_price_details: {
        original_price_transfer: selectedTransfer?.total_fare_markup,
        original_price_total_transfer:
          selectedTransfer?.search_passenger *
          selectedTransfer?.total_fare_markup,
        original_curreny_transfer: selectedTransfer?.sale_currency,
        exchange_price_transfer: StripePayment,
        exchange_price_total_transfer: StripePayment,
        exchange_curreny_transfer: StripeCurrency,
        destination_avail_id: selectedTransfer?.destination_id,
        no_of_paxs_transfer: selectedTransfer?.search_passenger
      }
    }
    const extraData = sessionStorage.getItem('Extradata');
    var bookingdata = {
      token: token,
      extras_Data:extraData,
      slc_pyment_method: JSON.stringify(paymentStatusResponse),
      transfer_data: JSON.stringify(LeadPassengerData),
      transfer_destination_data: JSON.stringify(selectedTransfer),
      booking_From: selectedTransfer.booking_From,
      response_confirm_booking: JSON.stringify(newdata)
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/transfer_checkout_submit_react',
        bookingdata,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsBooking(false)
      if (response.data.status === 'success') {
        navigate(`/transfer_invoice/${response.data.Invoice_no}`)
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      }
    } catch (error) {
      setIsBooking(false)
      console.error('Error:', error)
    }
  }

  const ThrirdPartyTransfer = async () => {
    if (isChecked) {
      if (gestinfo.title === '') {
        toast.error('Please Select Lead Guest Title.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.firstname === '') {
        toast.error('Please Enter Lead Guest First Name.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.lastname === '') {
        toast.error('Please Enter Lead Guest Last Name.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.email === '') {
        toast.error('Please Enter Lead Guest Email.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (selectedCountry === '' || selectedCountry === null) {
        toast.error('Please Select Lead Guest Nationality.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else if (gestinfo.phonenumber.length <= 5) {
        toast.error('Please Enter Phone Number.', {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      }
      setPaymentLoader(true);
      const currentURL = window.location.href
      const parsedUrl = new URL(currentURL);
      const queryParams = parsedUrl.searchParams;
      const id = queryParams.get('id');
      if(paymentStatus1 ===false){
        handlePrepareCheckout();
        setPaymentModal(true);
        return;
      }
      if(id===null ){
        handlePrepareCheckout();
        setPaymentModal(true);
        return;
      };
      setIsBooking(true);
      if (selectedTransfer.booking_From !== '3rd Party API') {
        BookTransfer();
        return;
      };
      const TransectionNumber = sessionStorage.getItem(
        'TransferTransectionNumber'
      );
      var bookingData = {
        country: selectedTransfer.country,
        propertyname: gestinfo.firstname,
        j1propertyname: gestinfo.lastname,
        accomodationaddress: 'H#10 St#9 etc',
        deppoint: selectedTransfer.country,
        depinfo: selectedTransfer.pickup_City,
        transactionnumber: TransectionNumber,
        title: gestinfo.title,
        firstname: gestinfo.firstname,
        lastname: gestinfo.lastname,
        email: gestinfo.email,
        phone: gestinfo.phonenumber,
        mobile: gestinfo.phonenumber
      }

      var data = {
        token: token,
        confirm_Booking_Arr: JSON.stringify(bookingData)
      }
      try {
        const response = await Axios.post(
          endpoint + '/api/confbook_Transfer_Api',
          data,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
      
        if (!response.data.error) {
          BookTransfer(response.data)
        } else {
          setIsBooking(false)
          toast.error(response.data.error, {
            position: toast.POSITION.TOP_RIGHT
          })
          return;
        }
      } catch (error) {
        setIsBooking(false)
        console.error('Error:', error)
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } else {
      toast.error('Please Agree with Terms and Conditions.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const handleCheckboxContinue = () => {
    setIsChecked(!isChecked)
  }

  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice = price
    } else {
      const conversionrate =
        GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency]
      exchangeRate = conversionrate
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0)
      var baseprice = newprice
    }
    return baseprice
  };
  const handlePrepareCheckout = async () => {
    saveFormData();
    try {
      const id = await prepareCheckout();
      if(id.success===false){
        setPaymentModal(false);
        return ;
      }
      setCheckoutId(id);
      sessionStorage.setItem('CHECKOUT_ID', id);
      setPaymentLoader(false);
    } catch (error) {
      console.error("Error preparing checkout:", error);
    }
  };
  const togglePayment=()=>{
    setPaymentModal(!paymentModal);
  };
  
  function saveFormData() {
    var Formdata={
      form:gestinfo,
      country:selectedCountry
    }
    sessionStorage.setItem('formData', JSON.stringify(Formdata));
};
function loadFormData() {
  const formData = JSON.parse(sessionStorage.getItem('formData'));
  if(formData !== null){
    setGestinfo(formData.form);
    setSelectedCountry(formData.country);
  }

};

  return (
    <>
      {isBooking && <Loading />}
      <Modal isOpen={paymentModal}  toggle={togglePayment}>
        <ModalHeader toggle={togglePayment}>Pay Now</ModalHeader>
          <ModalBody>
            {paymentLoader ?(
              <div className='payment-loader'>
              <img src={loaderimg}/>
            </div>
            ):(
              <PaymentForm checkoutId={checkoutId} shopperResultUrl='/transfer-checkout' />
            )}
          </ModalBody>
      </Modal>
      <ToastContainer />
      <Layout>
        <div className='contact-img'>
          <img src={bgimage} />
        </div>
        <div className='container'>
          <div className='row mt-2 p-3  modify-flight-search'>
            <TransferNew />
          </div>
          <div className='row mt-2'>
            <div className='col-lg-8 margin-checkout'>
              <div className='row'>
                <div className='hotel-checkout-shadow p-3'>
                <form id='FormData'>
                  <div className='row'>
                    <div>
                      <h4>Lead Guest Information</h4>
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Title</label>
                      <select
                        id='inputState'
                        name='title'
                        value={gestinfo.title}
                        onChange={handlegestchange}
                        class='form-control mt-2 form-select select-styling'
                      >
                        <option selected>Select Title</option>
                        <option value='MR'>Mr</option>
                        <option value='MRS'>Mrs</option>
                      </select>
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>First Name</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.firstname}
                        name='firstname'
                        onChange={handlegestchange}
                        placeholder='First Name'
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Last Name</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.lastname}
                        name='lastname'
                        onChange={handlegestchange}
                        placeholder='Last Name'
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Email</label>
                      <input
                        type='email'
                        class='form-control mt-2'
                        value={gestinfo.email}
                        name='email'
                        onChange={handlegestchange}
                        placeholder='Email'
                      />
                    </div>
                    <div class='form-group field-icon-wrap mt-2 col-md-6'>
                      <label className='fw-bold'>Nationality</label>
                      <Select
                        options={countryList}
                        isSearchable={true}
                        className='mt-2'
                        onChange={handleCountryChange}
                        value={selectedCountry}
                        getOptionLabel={option => (
                          <div>
                            <img
                              src={option.flag}
                              alt={option.label}
                              style={{ width: '20px', marginRight: '8px' }}
                            />
                            {option.label} ({option.phoneCode})
                          </div>
                        )}
                        getOptionValue={option => option.value}
                      />
                    </div>
                    <div class='form-group mt-2 col-md-6'>
                      <label className='fw-bold'>Phone Number</label>
                      <input
                        type='text'
                        class='form-control mt-2'
                        value={gestinfo.phonenumber}
                        name='phonenumber'
                        onChange={handlegestchange}
                        placeholder='Phone Number'
                      />
                    </div>
                  </div>
                  </form>
                </div>
              </div>
              <div class='row mt-4'>
                <div className='hotel-checkout-shadow p-3'>
                  <div class='form-check mt-2'>
                    <input
                      class='form-check-input'
                      type='checkbox'
                      checked={isChecked} // Set the checked state
                      onChange={handleCheckboxContinue}
                      id='invalidCheck'
                      required
                    />
                    <label class='form-check-label' for='invalidCheck'>
                      By continuing, you agree to the{' '}
                      <span style={{ color: 'red' }}>
                        {' '}
                        Terms and conditions
                      </span>
                    </label>
                  </div>
                  <div>
                  {paymentMessage && (
                      <div
                        style={{ color: 'green' }}
                        className='error mt-2'
                        role='alert'
                      >
                        {paymentMessage}
                      </div>
                    )}
                  {paymentStatus && (
                    <div>
                      <h2>Payment Status</h2>
                      <pre>{JSON.stringify(paymentStatus, null, 2)}</pre>
                    </div>
                  )}
                </div>
                  <button
                    className='btn fw-bold btn-warning'
                    onClick={ThrirdPartyTransfer}
                  >
                    {buttonText ? 'Pay Now':'Confirm Booking'}
                    
                  </button>
                </div>
              
              </div>
            </div>
            <div className='col-lg-4 margin-checkout'>
              <div className='checkout-hotel-detail p-3 tour_booking_amount_area'>
                <div className='Hotel-img'>
                  <div class='card-img mt-2 pb-2'>
                    <a href='hotel-single.html' class='d-block'>
                      <img
                        className='tour-img'
                        src={selectedTransfer.vehicle_image}
                        alt=''
                      />
                    </a>
                  </div>
                  <div className='card-body '>
                    <h4 class='card-title'>{selectedTransfer.VehicleMake}</h4>
                    <h6 className='mt-2'>Pickup Location</h6>
                    <p>{selectedTransfer.pickup_City}</p>
                    <h6 className='mt-2'>Dropof Location</h6>
                    <p>{selectedTransfer.dropof_City}</p>
                    {moreDestination.map((Loc, index) => (
                      <div key={index}>
                        <div className='border-line mt-2'></div>
                        <div class='container3 justify-content-center mt-2'>
                          <h5 class='text3 '>Destination {index + 2}</h5>
                        </div>
                        <div>
                          <h6>Pick-up Location </h6>
                          <p>{Loc.subLocationPic}</p>
                          <h6>Drop-off Location </h6>
                          <p>{Loc.subLocationdrop}</p>
                        </div>
                      </div>
                    ))}
                    <div className='border-line mt-2'></div>
                    <ul class='list-items  list-items-2  mt-2 py-2'>
                      <li>
                        <span>Vehicle Class:</span>
                        {selectedTransfer.vehicle_Name}
                      </li>
                      <li>
                        <span>Transfer Type:</span>
                        {selectedTransfer.transfer_type}
                      </li>
                      <li>
                        <span>Pickup Date:</span>
                        {selectedTransfer.pickup_date}
                      </li>
                      <li>
                        <span>Duration:</span>
                        {selectedTransfer.duration}
                      </li>
                      <li>
                        <span>Passenger:</span>
                        {selectedTransfer.search_passenger}
                      </li>
                      <li>
                        <span>Vehicles:</span>
                        {selectedTransfer.no_of_vehicles}
                      </li>
                    </ul>
                  </div>
                  <div class='border-line'></div>
                  <ul class='list-items  list-items-2  mt-2 py-2'>
                    <li>
                      <span>Sub Total:</span>
                      {CurrencyRates === undefined
                        ? selectedTransfer.sale_currency
                        : CurrencyRates.selectedcurrency}{' '}
                      {renderPrice(Number(selectedTransfer.total_fare_markup))}
                    </li>
                    <li>
                      <span>Tax:</span>0
                    </li>
                    <li>
                      <span>Total:</span>
                      {
                        (StripeCurrency =
                          CurrencyRates === undefined
                            ? selectedTransfer.sale_currency
                            : CurrencyRates.selectedcurrency)
                      }{' '}
                      {
                        (StripePayment = renderPrice(
                          Number(selectedTransfer.total_fare_markup)
                        ))
                      }
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

async function prepareCheckout() {
  const url = "https://oppwa.com/v1/checkouts";
  const token = "Bearer OGFjZGE0Y2E4MDQ2NGZhMTAxODA2MDBjNDEwZDA3MzZ8QU14cDhicmtEWA==";
  var NewResponse;
  var SarPrice=Number(StripePayment).toFixed(2);
  if (StripeCurrency !== 'SAR') {
    try {
      NewResponse = await CurrencyCalucaltion();
      if(NewResponse.result==='success'){
        var currencyPrice=NewResponse.conversion_rates['SAR'];
          SarPrice=(Number(StripePayment)*currencyPrice).toFixed(2);
        };
      // Use NewResponse here
    } catch (error) {
      console.error(error);
      toast.error('Price conversion Api is not working please try again after some time.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return { success: false } ;
    }
  };
 
  const data = {
    entityId: "8acda4ca80464fa10180600cddd20748",
    amount: String(SarPrice),
    currency: 'SAR',
    paymentType: "DB"
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Authorization": token,
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: new URLSearchParams(data)
  });

  const result = await response.json();
  return result.id;
}
const CurrencyCalucaltion = async () => {
  try {
    var token = CurrencyConverter();
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + StripeCurrency, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    const response = await Axios.request(config);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error(error);
    throw error; // Rethrow the error to handle it outside if needed
  }
};
// Function to get the payment status
async function getPaymentStatus() {
  const baseUrl = "https://oppwa.com";
  const token = "Bearer OGFjZGE0Y2E4MDQ2NGZhMTAxODA2MDBjNDEwZDA3MzZ8QU14cDhicmtEWA==";
  var NewId=sessionStorage.getItem('CHECKOUT_ID');
  const resourcePath = `/v1/checkouts/${NewId}/payment?entityId=8acda4ca80464fa10180600cddd20748`;

  const url = baseUrl + resourcePath;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Authorization": token
    }
  });

  const result = await response.json();
  return result;
}

function PaymentForm({ checkoutId, shopperResultUrl }) {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    
  }, [checkoutId]);

  return (
    <form action={shopperResultUrl} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
  );
}
export default TransferCheckout
