import React, { useEffect, useState } from 'react'
import img1 from '../../Images/Carousal/1.jpg'
import '../../Components/Carousal/style.css'
import img2 from '../../Images/Carousal/hero-1.jpg'
import img3 from '../../Images/Carousal/3.jpg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import img4 from '../../Images/Carousal/4.jpg'
import Carousel from 'react-bootstrap/Carousel'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Packages from '../../Components/Packages/Packages'
import agentpic from '../../Images/Home/agentpic.png'
import Hotels from '../../Components/Hotel/Hotels'
import MorePackages from '../../Components/MorePackages/MorePackages'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEnvelope, faHeadphones, faMoneyBill, faPhone, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
function Home () {
  const [isLoading, setIsLoading] = useState(true)
  const [bottomAdd, setShowbottomAdd] = useState(true)
  const [modalOpen,setModalOpen]=useState(true);
  useEffect(() => {
    
    new wow.WOW().init()
    const fetchData = async () => {
      // Replace this with your actual data fetching logic or any other asynchronous tasks
      // await new Promise(resolve => setTimeout(resolve, 2000))
      await new Promise(resolve => setTimeout(resolve, 2000))
      // Once data is fetched or tasks are completed, set loading to false
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const toggleModal=()=>{
    setModalOpen(!modalOpen);
  };

  const hideadd = () => {
    setShowbottomAdd(false)
  };
  return (
    <>
      <Helmet>
        <title>Umrah Tech</title>
      </Helmet>
      {isLoading && <Loader />}
      <Layout>
        <div className='overlay-container'>
          <Carousel className='carousel-container'>
            <Carousel.Item>
              <img className='d-block w-100' src={img2} alt='First slide' />
            </Carousel.Item>
          
          </Carousel>
          <SearchBar />
        </div>
        <Packages />
        {/* <Hotels/> */}
        <MorePackages />
      </Layout>
     
   
    </>
  )
}

export default Home
