import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store'; 
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
store.subscribe(()=>store.getState())
const root = ReactDOM.createRoot(document.getElementById('root'));
// pk_test_51N9pi8GwYlRApJ0w8Lz2o0lVKIUqAAd4S2LPB5mY2GHu2K2CWtYqbYBNgCayxu5iTygzw2iyS5mJVHhSM6IfGDJZ00mtIuhi5H
// pk_live_51N9pi8GwYlRApJ0wG7yS3NQboZU7cUlBAHxUnolT1UsEeqy4iyMPcmNBs2cgp8o70mN7EPzQkCAdMNhkR5YlXLjH006aOplm1v
const stripePromise = loadStripe('pk_live_51N9pi8GwYlRApJ0wG7yS3NQboZU7cUlBAHxUnolT1UsEeqy4iyMPcmNBs2cgp8o70mN7EPzQkCAdMNhkR5YlXLjH006aOplm1v');

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={stripePromise}>
        <App/>
      </Elements>
      
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
