import React from 'react'
function Googlemap () {
  return (
    <>
      <div className='container'>
        <div className='col-12'>
          <div className='responsive-map'>
          
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237684.58519120407!2d39.68173699176972!3d21.436254366608576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c21b4ced818775%3A0x98ab2469cf70c9ce!2sMakkah%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1706022330344!5m2!1sen!2s'
              width='600'
              height='450'
              frameborder='0'
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default Googlemap
