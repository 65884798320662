import React,{useEffect} from 'react';
import image from '../../Images/Hotels/hotels.jpg';
import img2 from '../../Images/Pages/contact.png';
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';

function About () {

useEffect(()=>{
    new wow.WOW().init();
},[]);

  return (
    <>
    <Layout>
      
    <div className="contact-img" >
                <img src={img2}/>
                <h1 className="text-capitalize fw-bold" style={{color:'#363d48'}}>About Us</h1>
            </div>
      <section className='about-us-section mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-7 wow animate__animated animate__slideInLeft' data-wow-duration="1s" data-wow-delay="0.2s">
              <h1>About Umrah Tech</h1>
              <p>
              Umrah Tech, the ultimate destination for travelers seeking the perfect hotel experience. Founded in [Year of Establishment], we have rapidly grown into a trusted online resource, dedicated to making hotel booking an effortless, enjoyable, and affordable endeavor for every traveler.
              </p>
              <h3 className='mt-2'>Our Mission</h3>
          <p>
          At Umrah Tech, our mission is simple: to connect travelers with their ideal accommodations anywhere in the world. Whether you're planning a romantic getaway, a family vacation, a business trip, or a spontaneous adventure, our platform is designed to cater to your unique needs and preferences.
          </p>
            </div>
            <div className='col-sm-5 wow animate__animated animate__slideInRight' data-wow-duration="1s" data-wow-delay="0.2s">
              <img src={image} alt='' />
            </div>
          </div>
          <div className="wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
          <h3>Why Choose Us?</h3>
          </div>
          <div className=' row about-4' >
            <div className='wow  animate__animated animate__slideInLeft col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>Extensive Selection</h3>
              <p>
              We offer an extensive range of hotels, resorts, B&Bs, and unique lodging options in destinations across the globe. From luxury five-star hotels to cozy boutique properties and budget-friendly hostels, our collection ensures that you find the perfect stay for any occasion.
              </p>
            </div>
            <div className='wow animate__animated animate__slideInRight col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>Unbeatable Prices</h3>
              <p>
              Leveraging our extensive network and industry partnerships, we negotiate the best possible rates for our customers. Enjoy exceptional value without compromising on quality or comfort.
              </p>
            </div>
            <div className='wow animate__animated animate__slideInLeft col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>User-Friendly Experience</h3>
              <p>
              Our website is designed with you in mind. Easy navigation, detailed hotel descriptions, high-quality images, and honest customer reviews make it simple to find and book your ideal hotel.
              </p>
            </div>
            <div className='wow animate__animated animate__slideInRight col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>Secure and Reliable</h3>
              <p>
              Your security and privacy are our top priorities. Our platform uses advanced encryption technology to ensure your personal and payment information is protected at all times.
              </p>
            </div>
          </div>
        </div>
      </section>
      </Layout>
    </>
  )
}

export default About
