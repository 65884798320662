export function Hotelapitoken(){
    return 'WgVG5GScox48YWIQXTMX7DRe6YsWLaIDBOlR7KMX-8y4CsWo09VpyxjBs1yNNOzAwPtnf76nk0Czd5sgaftnksPMIIkmmPq894r2H-UrWnNhWSdKzSrBI7nMxht9kIZ-1Rj9fjUa5Hnx18AEtHOfA6ZjEEf8B3Sh091voHcBxjEG8meuhJTSKFj5C9qozm8q0phNTHCgcqX-Pz1xJAkXWEdW5CQWvD0OlXtF0jT5XsqFcDKclgCA1Qbgsg9NMuT3qBiiO8X7dtAZ9ullOOLqncNNiBEK88b9ALZiLk9sXxj05H3Q-XWFwun1xnerh0zyClQXYgb9pOYLAKfeUrLxvKNKJpOK18844hQftloFcFJvHWVgf8qmmpPFu938-WssZR9VSJYO8KtVfH8PSvXCys-eLFy0DLWAGsh1umRVwNUSsPiogwFmwEm39kZ4mzgiiAIEEbuhp-da4AlIqkdzQhUxyJhuORSoprRRWhNK5DefD3jhC8gAstuijhct'
};

export function CustomerDomainName(){
    return 'https://system.alhijaztours.net/'
};
export function FlightSearchToken(){
    return 'WgVG5GScox48YWIQXTMX7DRe6YsWLaIDBOlR7KMX-8y4CsWo09VpyxjBs1yNNOzAwPtnf76nk0Czd5sgaftnksPMIIkmmPq894r2H-UrWnNhWSdKzSrBI7nMxht9kIZ-1Rj9fjUa5Hnx18AEtHOfA6ZjEEf8B3Sh091voHcBxjEG8meuhJTSKFj5C9qozm8q0phNTHCgcqX-Pz1xJAkXWEdW5CQWvD0OlXtF0jT5XsqFcDKclgCA1Qbgsg9NMuT3qBiiO8X7dtAZ9ullOOLqncNNiBEK88b9ALZiLk9sXxj05H3Q-XWFwun1xnerh0zyClQXYgb9pOYLAKfeUrLxvKNKJpOK18844hQftloFcFJvHWVgf8qmmpPFu938-WssZR9VSJYO8KtVfH8PSvXCys-eLFy0DLWAGsh1umRVwNUSsPiogwFmwEm39kZ4mzgiiAIEEbuhp-da4AlIqkdzQhUxyJhuORSoprRRWhNK5DefD3jhC8gAstuijhct'
};

export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
};

export function CurrencyConverter(){
    return '3eb7d21b288ab2789b33ef02'
};
export function StripeClientSecret(){
    // return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    return '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'
};
export function ActivityToken(){
    return 'WgVG5GScox48YWIQXTMX7DRe6YsWLaIDBOlR7KMX-8y4CsWo09VpyxjBs1yNNOzAwPtnf76nk0Czd5sgaftnksPMIIkmmPq894r2H-UrWnNhWSdKzSrBI7nMxht9kIZ-1Rj9fjUa5Hnx18AEtHOfA6ZjEEf8B3Sh091voHcBxjEG8meuhJTSKFj5C9qozm8q0phNTHCgcqX-Pz1xJAkXWEdW5CQWvD0OlXtF0jT5XsqFcDKclgCA1Qbgsg9NMuT3qBiiO8X7dtAZ9ullOOLqncNNiBEK88b9ALZiLk9sXxj05H3Q-XWFwun1xnerh0zyClQXYgb9pOYLAKfeUrLxvKNKJpOK18844hQftloFcFJvHWVgf8qmmpPFu938-WssZR9VSJYO8KtVfH8PSvXCys-eLFy0DLWAGsh1umRVwNUSsPiogwFmwEm39kZ4mzgiiAIEEbuhp-da4AlIqkdzQhUxyJhuORSoprRRWhNK5DefD3jhC8gAstuijhct'
};
export function TransferApiToken(){
    return 'WgVG5GScox48YWIQXTMX7DRe6YsWLaIDBOlR7KMX-8y4CsWo09VpyxjBs1yNNOzAwPtnf76nk0Czd5sgaftnksPMIIkmmPq894r2H-UrWnNhWSdKzSrBI7nMxht9kIZ-1Rj9fjUa5Hnx18AEtHOfA6ZjEEf8B3Sh091voHcBxjEG8meuhJTSKFj5C9qozm8q0phNTHCgcqX-Pz1xJAkXWEdW5CQWvD0OlXtF0jT5XsqFcDKclgCA1Qbgsg9NMuT3qBiiO8X7dtAZ9ullOOLqncNNiBEK88b9ALZiLk9sXxj05H3Q-XWFwun1xnerh0zyClQXYgb9pOYLAKfeUrLxvKNKJpOK18844hQftloFcFJvHWVgf8qmmpPFu938-WssZR9VSJYO8KtVfH8PSvXCys-eLFy0DLWAGsh1umRVwNUSsPiogwFmwEm39kZ4mzgiiAIEEbuhp-da4AlIqkdzQhUxyJhuORSoprRRWhNK5DefD3jhC8gAstuijhct'
};
export function NewTransferSearchToken(){
    return 'WgVG5GScox48YWIQXTMX7DRe6YsWLaIDBOlR7KMX-8y4CsWo09VpyxjBs1yNNOzAwPtnf76nk0Czd5sgaftnksPMIIkmmPq894r2H-UrWnNhWSdKzSrBI7nMxht9kIZ-1Rj9fjUa5Hnx18AEtHOfA6ZjEEf8B3Sh091voHcBxjEG8meuhJTSKFj5C9qozm8q0phNTHCgcqX-Pz1xJAkXWEdW5CQWvD0OlXtF0jT5XsqFcDKclgCA1Qbgsg9NMuT3qBiiO8X7dtAZ9ullOOLqncNNiBEK88b9ALZiLk9sXxj05H3Q-XWFwun1xnerh0zyClQXYgb9pOYLAKfeUrLxvKNKJpOK18844hQftloFcFJvHWVgf8qmmpPFu938-WssZR9VSJYO8KtVfH8PSvXCys-eLFy0DLWAGsh1umRVwNUSsPiogwFmwEm39kZ4mzgiiAIEEbuhp-da4AlIqkdzQhUxyJhuORSoprRRWhNK5DefD3jhC8gAstuijhct'
};
export function TransferCheckoutToken(){
    return 'WgVG5GScox48YWIQXTMX7DRe6YsWLaIDBOlR7KMX-8y4CsWo09VpyxjBs1yNNOzAwPtnf76nk0Czd5sgaftnksPMIIkmmPq894r2H-UrWnNhWSdKzSrBI7nMxht9kIZ-1Rj9fjUa5Hnx18AEtHOfA6ZjEEf8B3Sh091voHcBxjEG8meuhJTSKFj5C9qozm8q0phNTHCgcqX-Pz1xJAkXWEdW5CQWvD0OlXtF0jT5XsqFcDKclgCA1Qbgsg9NMuT3qBiiO8X7dtAZ9ullOOLqncNNiBEK88b9ALZiLk9sXxj05H3Q-XWFwun1xnerh0zyClQXYgb9pOYLAKfeUrLxvKNKJpOK18844hQftloFcFJvHWVgf8qmmpPFu938-WssZR9VSJYO8KtVfH8PSvXCys-eLFy0DLWAGsh1umRVwNUSsPiogwFmwEm39kZ4mzgiiAIEEbuhp-da4AlIqkdzQhUxyJhuORSoprRRWhNK5DefD3jhC8gAstuijhct'
};
// WgVG5GScox48YWIQXTMX7DRe6YsWLaIDBOlR7KMX-8y4CsWo09VpyxjBs1yNNOzAwPtnf76nk0Czd5sgaftnksPMIIkmmPq894r2H-UrWnNhWSdKzSrBI7nMxht9kIZ-1Rj9fjUa5Hnx18AEtHOfA6ZjEEf8B3Sh091voHcBxjEG8meuhJTSKFj5C9qozm8q0phNTHCgcqX-Pz1xJAkXWEdW5CQWvD0OlXtF0jT5XsqFcDKclgCA1Qbgsg9NMuT3qBiiO8X7dtAZ9ullOOLqncNNiBEK88b9ALZiLk9sXxj05H3Q-XWFwun1xnerh0zyClQXYgb9pOYLAKfeUrLxvKNKJpOK18844hQftloFcFJvHWVgf8qmmpPFu938-WssZR9VSJYO8KtVfH8PSvXCys-eLFy0DLWAGsh1umRVwNUSsPiogwFmwEm39kZ4mzgiiAIEEbuhp-da4AlIqkdzQhUxyJhuORSoprRRWhNK5DefD3jhC8gAstuijhct
// 3eb7d21b288ab2789b33ef02
//  32ce58d166a15bca2a55cd7a new
//c64fcc6bcffe09ea76ea37b7 latest