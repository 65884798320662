import React,{useState,useEffect} from 'react'
import alaqsa from '../../Images/Packages/AlAqsa-package.jpg'
import turky from '../../Images/Packages/Turky-packages.jpg'
import umrah from '../../Images/Packages/umrah-package.jpg'
import gallery1 from '../../Images/Home/jeddah (1).jpg'
import gallery2 from '../../Images/Home/makkah1.jpg'
import gallery3 from '../../Images/Home/madina1.jpg'
import gallery4 from '../../Images/Home/dubai1.jpg'
import gallery5 from '../../Images/Home/london1.jpg'
import gallery6 from '../../Images/Home/01.jpg'
import mobile from '../../Images/Home/01 (1).png'
import get2 from '../../Images/Home/app-store.png'
import get1 from '../../Images/Home/google-play.png'



import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faHeadset,
  faPersonWalkingLuggage,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { Hotelapitoken,ApiEndPoint } from '../GlobalData/GlobalData'
function MorePackages () {
  const [packages,setPackages]=useState([]);
  useEffect(()=>{
    GetPackages();
  },[]);

  const GetPackages = async () => {
    var token = Hotelapitoken();
    var apiendpoint=ApiEndPoint();
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/latest_packages_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      var packagesCount;
      if (response.data.latest_packages.length !== 0) {
        var umrah = response.data.latest_packages[0].length
        var alaqsa = response.data.latest_packages[1].length
        var turkey = response.data.latest_packages[2].length
        packagesCount = {
          umrahs: umrah,
          alaqsas: alaqsa,
          turkeys: turkey
        }
      }
      setPackages(packagesCount);
    
    } catch (error) {
      console.error('Error:', error)
    }
  };

  return (
    <>
     <div className="container mt-5 mb-5">
        <div className="row align-items-center justify-content-between g-4">

          <div className=" col-md-4 col-sm-6">
            <div className="featuresBox-wrap">
              <div className="featuresBox-icons mb-3">
                <i className="fa-solid fa-sack-dollar fs-1 text-primary"></i>
                <FontAwesomeIcon icon={faSackDollar}/>
              </div>
              <div className="featuresBox-captions">
                <h4 className="fw-bold fs-5 lh-base mb-0">Easy Booking</h4>
                <p className="m-0"> Our easy booking process ensures a seamless and efficient reservation experience. Select your preferences and confirm your booking in just a few clicks. Enjoy the convenience of planning your  trips effortlessly.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="featuresBox-wrap">
              <div className="featuresBox-icons mb-3">
                <i className="fa-solid fa-umbrella-beach fs-1 text-primary"></i>
                <FontAwesomeIcon icon={faUmbrellaBeach}/>
              </div>
              <div className="featuresBox-captions">
                <h4 className="fw-bold fs-5 lh-base mb-0">Best Destinations</h4>
                <p className="m-0">Immerse yourself in unparalleled beauty and discover top-rated locations that promise memorable moments.Whether you seek relaxation, adventure, or cultural enrichment , our best destinations guarantee an extraordinary journey.</p>
              </div>
            </div>
          </div>

          <div className=" col-md-4 col-sm-6">
            <div className="featuresBox-wrap">
              <div className="featuresBox-icons mb-3">
                <i className="fa-solid fa-person-walking-luggage fs-1 text-primary"></i>
                <FontAwesomeIcon icon={faPersonWalkingLuggage}/>
              </div>
              <div className="featuresBox-captions">
                <h4 className="fw-bold fs-5 lh-base mb-0">Travel Guides</h4>
                <p className="m-0">Dive into rich cultural insights, dining recommendations, and must-see attractions, ensuring every trip is infused with authenticity and excitement. Let our Travel Guides be your trusted companion in exploring the world.</p>
              </div>
            </div>
          </div>

        
        </div>
      </div>
      

      <div className="destination-area pb-20 d-none d-sm-block ">
            <div className="container">
            <div className="row">
            <div className="col-lg-12"> 
            <div className="site-heading text-center">
            <span className="site-title-tagline">Destination</span>
            <h2 className="site-title">Our Most Popular Destinations</h2>
            </div>
            </div>
            </div>
            <div className="row align-items-center">
            <div className="col-md-12 col-lg-6">
            <div className="destination-item">
            <div className="destination-img">
            <img src={gallery1} />
            </div>
            <div className="destination-info">
            <h4><FontAwesomeIcon icon={faArrowRight}/> Jeddah</h4>
           
           
            </div>
           
            </div>
            </div>
            <div className="col-md-6 col-lg-3">
            <div className="destination-item">
            <div className="destination-img">
            <img src={gallery2} />
            </div>
            <div className="destination-info">
              <h4><FontAwesomeIcon icon={faArrowRight}/>  Makkah</h4>
            
            </div>
           
            </div>
            </div>
            <div className="col-md-6 col-lg-3">
            <div className="destination-item">
            <div className="destination-img">
            <img src={gallery3} />
            </div>
            <div className="destination-info">
            <h4><FontAwesomeIcon icon={faArrowRight}/> Madina</h4>
          
            
            </div>
           
            </div>
            </div>
            <div className="col-md-6 col-lg-3">
            <div className="destination-item">
            <div className="destination-img">
            <img src={gallery4} />
            </div>
            <div className="destination-info">
            <h4><FontAwesomeIcon icon={faArrowRight}/> Dubai</h4>
            
            
            </div>
           
            </div>
            </div>
            <div className="col-md-6 col-lg-3">
            <div className="destination-item">
            <div className="destination-img">
            <img src={gallery5} />
            </div>
            <div className="destination-info">
            <h4><FontAwesomeIcon icon={faArrowRight}/> London</h4>
            
            </div>
            
            </div>
            </div>
            <div className="col-md-12 col-lg-6">
            <div className="destination-item">
            <div className="destination-img">
            <img src={gallery6} />
            </div>
            <div className="destination-info">
            <h4><FontAwesomeIcon icon={faArrowRight}/> New York City</h4>
           
            </div>
            
            </div>
            </div>
            </div>
            </div>
            </div>

{/* 
            <div class="download-area pt-80">
            <div class="container">
            <div class="row">
            <div class="col-lg-6">
            <div class="download-img ">
            <img src={mobile} />
            </div>
            </div>
            <div class="col-lg-6">
            <div class="download-content">
            <div class="site-heading mb-0">
            <span class="site-title-tagline">Download</span>
            <h2 class="site-title">Umrah Tech Android and IOS App is Available! Download Now</h2>
            
           
            <div class="download-link mt-5">
            <a href="#"><img src={get1} /></a>
            <a href="#"><img src={get2} /></a>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div> */}





    </>
  )
}

export default MorePackages
