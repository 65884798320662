import React, { useState, useEffect } from 'react'
import img1 from '../../Images/Packages/umrah-package.jpg'
import alminhaj from '../../Images/Home/alminhaj.jpg'
import img2 from '../../Images/Home/bg-video.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSquareCheck,
  faHeadset,
  faPersonWalkingLuggage,
  faPlaneArrival,
  faPlaneDeparture,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import CIcon from '@coreui/icons-react'
import { cilMoon } from '@coreui/icons'
import Loading from '../Loading/Loader'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useDispatch } from 'react-redux'
import {
  Hotelapitoken,
  CustomerDomainName,
  ApiEndPoint
} from '../GlobalData/GlobalData'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Axios from 'axios'
function Packages () {
  const [isloading, setLoading] = useState(false)
  const [packagescard, setPackagescard] = useState([])
  const [allcateogries, setAllCateogries] = useState([])
  const [allTours, setAllTours] = useState([])
  const [showTours, setShowTours] = useState([])
  const [itemsToShow, setItemsToShow] = useState(4)
  var url = CustomerDomainName()
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const [activeItem, setActiveItem] = useState(0) // Initialize activeItem with the default value (0).
  var apiendpoint = ApiEndPoint()
  const handleCategorySelect = index => {
    setActiveItem(index)
    var filter = allTours.filter(
      tour => Number(tour.categories) === allcateogries[index].id
    )
    setShowTours(filter)
    // Set the selected category as the active one.
  }
  useEffect(() => {
    GetPackages()
  }, [])

  useEffect(() => {
    // Add an event listener to track window width changes
    function handleResize () {
      if (window.innerWidth > 1000) {
        setItemsToShow(4) // For smaller screens, show 1 item
      } else if (window.innerWidth > 768 && window.innerWidth < 1000) {
        setItemsToShow(3) // For smaller screens, show 1 item
      } else if (window.innerWidth > 470 && window.innerWidth < 768) {
        setItemsToShow(2) // For smaller screens, show 1 item
      } else if (window.innerWidth < 470) {
        setItemsToShow(1) // For larger screens, show 4 items (you can adjust this)
      }
    }

    // Initialize the number of items based on the current window width
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);


  const GetPackages = async () => {
    var token = Hotelapitoken();

    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/latest_packages',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setAllCateogries(response.data.all_cateogries)
      var filter = response.data.latest_packages[0].filter(
        tour =>
          Number(tour.categories) ===
          response.data.all_cateogries[activeItem].id
      )
      setShowTours(filter)
      setAllTours(response.data.latest_packages[0])
    } catch (error) {
      console.error('Error:', error)
    }
  };

  const fetchViewDetail = async (event, id,name) => {
    event.preventDefault()
    var apitoken = Hotelapitoken()
    setLoading(true)
    var data = {
      token: apitoken,
      id: id,
      type: 'tour',
      provider_token: ''
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_tour_details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      Dispatch(ViewTourDetail(response.data))
      const result = name.split(' ').join('-');
      navigation(`/umrah-package/${result}`)
      setLoading(true)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <>
      {isloading && <Loading />}
      <section class="section-style1 mt-5">
         <div class="container page-builder-ltr">
            <div class="row row-style row_a1">
               <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col_a1c about-text">
                  <h3><span>About us</span></h3>
                  <p> Umrah Tech for Hajj and Umrah Services, We provide B2C Hajj and Umrah Solutions for our WorldWide customers</p>
                  <ul>
                     <li> <FontAwesomeIcon  icon={faSquareCheck}/> Makkah Hotels</li>
                     <li> <FontAwesomeIcon  icon={faSquareCheck}/> Madina Hotels</li>
                     <li> <FontAwesomeIcon  icon={faSquareCheck}/> Transportation</li>
                     <li> <FontAwesomeIcon  icon={faSquareCheck}/> Ground Services</li>
                     <li> <FontAwesomeIcon  icon={faSquareCheck}/> Visa</li>
                     {/* <li><a href="https://www.almnhaj.com/" target="_blank">   <img src={alminhaj} /></a></li> */}
                     {/* <a href="https://www.almnhaj.com/" target="_blank">
                     </a> */}
                  </ul>
                  <div class="about-more clearfix"><a class="btn-grad" href="#">READ MORE  <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col_a1c about-video">
               <img src={img2} />
                   
                  </div>
               </div>
            </div>
         </section>









    </>
  )
}

export default Packages
